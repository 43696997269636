import { FlexRowGap } from '@/components/library/basic-styled-components';
import SecondaryButton from '@/components/library/buttons/secondary-button';
import CollabActionBar from '@/components/library/collab-action-bar';
import IconToggle from '@/components/library/icon-toggle';
import UserImg from '@/components/user-img';
import { likeCollab, saveCollab } from '@/store/landing';
import { Bookmark, Heart, MessageCircle, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CollabHeader = ({ collab, scrollToComments, liked, saved }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.currentUser);

  const isLoggedIn = currentUser.id;
  return (
    <div className="collab-header">
      <FlexRowGap $gap="10px">
        {collab.visual && <UserImg user={collab.visual.user} />}
        {collab.audio && <UserImg user={collab.audio.user} />}
        {collab.writing && <UserImg user={collab.writing.user} />}
      </FlexRowGap>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {isLoggedIn ? (
          <CollabActionBar $gap={'16px'}>
            <IconToggle
              toggled={liked}
              toggle={() => dispatch(likeCollab(collab.id))}
              icon={<Heart />}
              fillClass="fill-heart"
            />
            <IconToggle
              toggled={saved}
              toggle={() => dispatch(saveCollab(collab.id))}
              icon={<Bookmark />}
              fillClass="fill-bookmark"
            />
            <div
              role="link"
              tabIndex={0}
              onClick={scrollToComments}
              onKeyPress={scrollToComments}
            >
              <MessageCircle />
            </div>
            <SecondaryButton
              className="btn"
              onClick={() => navigate('/')}
              $round
            >
              <X />
            </SecondaryButton>
          </CollabActionBar>
        ) : (
          <SecondaryButton className="btn" onClick={() => navigate('/')} $round>
            <X />
          </SecondaryButton>
        )}
      </div>
    </div>
  );
};

export default CollabHeader;
