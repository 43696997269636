import bardAPI from '@/bard-api';
import AudioPlayer from '@/components/audio-player/index';
import IntroBanner from '@/components/intro-banner';
import {
  FlexColumn,
  FlexRowGap,
} from '@/components/library/basic-styled-components';
import {
  ContentContainer,
  PageContainer,
} from '@/components/library/containers';
import LoadingEmptyState from '@/components/library/loading-empty-state';
import {
  ImportantText,
  SecondaryText,
  Text,
} from '@/components/library/typography/text';
import Navbar from '@/components/navbar/index';
import UserImgDefault from '@/components/user-img-default';
import ArtistResult from '@/containers/artists/artist-result';
import ArtistSearch from '@/containers/artists/artist-search';
import { SocialPostsAllowedDot } from '@/containers/profile/components';
import { uRequestHandler } from '@/utils';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const AudioResult = styled.div`
  background-color: ${({ theme }) => theme.colors.card.background};
  padding: 8px;
  border-radius: 6px;
  align-items: center;
  width: max-content;
`;

const Artists = () => {
  const [searching, setSearching] = useState(false);
  const [offset, setOffset] = useState(1);
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchString, setSearchString] = useState(`search=&`);
  const navigate = useNavigate();

  useEffect(() => {
    const getPage = async () => {
      setSearching(true);

      try {
        const response = await bardAPI.get(
          `profiles/?${searchString}&offset=${offset * 20}&limit=20`,
        );

        setUsers((users) => users.concat(response.data.results));
        setTotalUsers(response.data.count);
        setSearching(false);
      } catch (error) {
        setSearching(false);
        uRequestHandler(error);
      }
    };

    getPage();
  }, [offset, searchString]);

  useEffect(() => {
    const getInitialData = async () => {
      setSearching(true);
      const response = await bardAPI.get(
        `profiles/?${searchString}&offset=0&limit=20`,
      );
      setUsers(response.data.results);
      setSearching(false);
    };

    getInitialData();
  }, []);

  const changeSearch = (search) => {
    setUsers([]);
    setSearchString(search);
    setOffset(0);
  };

  const trackScrolling = useCallback(() => {
    const wrappedElement = document.getElementById('users-list-container');
    if (
      wrappedElement.getBoundingClientRect().bottom <=
        window.innerHeight + 700 &&
      !searching &&
      users.length < totalUsers
    ) {
      setOffset((offset) => offset + 1);
    }
  }, [searching, users, totalUsers]);

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);
    return () => document.removeEventListener('scroll', trackScrolling);
  }, [trackScrolling]);

  const renderPost = (post, contentType) => (
    <>
      {contentType === 'visuals' && (
        <div
          className="content-wrapper"
          key={`visual-${post.id}`}
          id={`visual-${post.id}`}
        >
          <Link to={`/visual/${post.id}`}>
            <img
              src={`https://${import.meta.env.VITE_APP_S3}/${post.file_path}`}
              alt="Unsuccessfully loaded."
            />
          </Link>
        </div>
      )}
      {contentType === 'audios' && (
        <div
          className="content-wrapper"
          key={`audio-${post.id}`}
          id={`audio-${post.id}`}
        >
          <AudioResult>
            <AudioPlayer
              audio={post}
              stacked
              onTitleClicked={() => navigate(`/audio/${post.id}`)}
            />
          </AudioResult>
        </div>
      )}
      {contentType === 'writings' && (
        <div
          className="content-wrapper"
          key={`writing-${post.id}`}
          id={`writing-${post.id}`}
        >
          <Link to={`/writing/${post.id}`}>
            <div
              className="story"
              dangerouslySetInnerHTML={{ __html: post.writing }}
            />
          </Link>
        </div>
      )}
      {contentType === 'voices' && (
        <div
          className="content-wrapper"
          key={`voice-${post.id}`}
          id={`voice-${post.id}`}
        >
          <AudioPlayer
            audio={post}
            stacked
            onTitleClicked={() => navigate(`/audio/${post.id}`)}
          />
        </div>
      )}
    </>
  );

  const currentUser = useSelector((state) => state.currentUser);
  return (
    <PageContainer id="users-list-container">
      <Navbar />

      <IntroBanner
        header={'Artists'}
        subHeader={'Explore artists and find open commissions and services.'}
      />

      <ArtistSearch searching={searching} search={changeSearch} />

      <ContentContainer>
        {users.map((user) => {
          const showSocialDot =
            user.social_posts_allowed && currentUser.is_admin;

          return (
            <ArtistResult key={user.id}>
              <Link to={`/profile/${user.username}`}>
                <UserImgDefault user={user} size="sm" />
                {showSocialDot && <SocialPostsAllowedDot $offset="-2px" />}
              </Link>
              <FlexRowGap $gap="24px" $width="100%">
                <FlexColumn $gap="8px" $minWidth="200px">
                  <div>
                    <ImportantText>{user.username}</ImportantText>
                  </div>
                </FlexColumn>
                {user.bio ? (
                  <Text>{user.bio}</Text>
                ) : (
                  <SecondaryText>
                    Apparently, this user prefers to keep an air of mystery
                    about them.
                  </SecondaryText>
                )}
              </FlexRowGap>
            </ArtistResult>
          );
        })}
        <LoadingEmptyState
          noResults="There are no artists matching that search criteria"
          noData="There are no artists on this site"
          isLoading={searching}
          data={users}
          search={searchString}
        />
      </ContentContainer>
    </PageContainer>
  );
};

export default Artists;
