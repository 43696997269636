import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  .collab-header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 112%;
    margin: 0 0 80px -6%;

    @media (max-width: 700px) {
      width: 100%;
      margin: 0 0 80px 0;
    }
  }

  .collab-swap-icons {
    position: absolute;
    right: 0px;
    top: 32px;
    margin-bottom: 16px;
  }
`;

export default Wrapper;
