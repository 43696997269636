import CollabsList from '@/components/collabs-list';
import CollectionsList from '@/components/collections-list';
import CopyLink from '@/components/copy-link';
import {
  FlexRowGap,
  FlexRowSpace,
} from '@/components/library/basic-styled-components';
import TertiaryButton from '@/components/library/buttons/tertiary-button';
import { TabButton } from '@/components/library/buttons/text-button';
import {
  BottomSection,
  ContentContainer,
  PageContainer,
} from '@/components/library/containers';
import PageModalHeader from '@/components/library/page-modal-header';
import { SectionHeader } from '@/components/library/typography/header';
import {
  Instructions,
  SubText,
  Text,
} from '@/components/library/typography/text';
import EditPost from '@/components/modals/edit-post';
import Tags from '@/components/tags';
import UserHeader from '@/components/user-header';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const PostContent = styled.div`
  min-width: 400px;
`;

const MetaData = styled.div`
  width: 400px;
  margin-bottom: auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .meta-content {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    background-color: ${({ theme }) => theme.colors.card.background};
  }

  @media (max-width: 900px) {
    margin-top: 0px;
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin: auto;
  width: fit-content;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const PostLayout = ({
  post,
  linkRoot,
  collabs,
  collections,
  reportCategory,
  content,
  savePost,
}) => {
  const TAB_COLLABS = 1;
  const TAB_COLLECTIONS = 2;
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.currentUser);
  const [showModal, setShowModal] = useState(false);
  const [tab, setTab] = useState(TAB_COLLABS);

  useEffect(() => {
    setShowModal(false);
  }, [post]);

  const leavePage = () => {
    if (navigate.length === 1) {
      navigate(`/profile/${post.user.username}`);
    } else {
      navigate(-1);
    }
  };

  const isPostCurrentUsers =
    currentUser.id && post.id && currentUser.id === post.user.id;
  const hideVisual = linkRoot === 'visual';
  const hideAudio = linkRoot === 'audio';
  const hideWriting = linkRoot === 'writing';
  const filteredCollabs = collabs.filter((collab) => {
    const hideWholePost =
      (hideVisual && !collab.writing && !collab.audio) ||
      (hideAudio && !collab.writing && !collab.visual) ||
      (hideWriting && !collab.audio && !collab.visual);

    return !hideWholePost;
  });
  if (!post.id) return <></>;
  return (
    <PageContainer $modal={true}>
      <ContentContainer>
        <PageModalHeader title={post.name} close={() => leavePage()} />

        <ContentWrapper>
          <PostContent>
            <UserHeader
              user={post.user}
              reportObject={post}
              reportCategory={reportCategory}
              removeData={() => navigate(`/profile/${post.user.username}`)}
            />

            {/* The main content for the post */}
            {content}
          </PostContent>

          <MetaData>
            <div className="meta-content">
              <FlexRowSpace>
                <SectionHeader>Metadata</SectionHeader>
                <FlexRowGap $gap="12px">
                  <CopyLink
                    link={`${import.meta.env.VITE_APP_WEB}${linkRoot}/${post.id}`}
                  />
                  {isPostCurrentUsers && (
                    <TertiaryButton
                      className="btn"
                      onClick={() => setShowModal(true)}
                    >
                      Edit
                    </TertiaryButton>
                  )}
                </FlexRowGap>
              </FlexRowSpace>

              <Text>{post.about}</Text>
              <Tags tags={post.tags.map((appliedTag) => appliedTag.tag.text)} />

              <SubText>
                Posted {new Date(post.created_date).toLocaleDateString()}
              </SubText>
            </div>
          </MetaData>
        </ContentWrapper>
      </ContentContainer>

      <BottomSection>
        <ContentContainer>
          <div className="collab-about">
            <FlexRowGap $gap="2rem">
              <TabButton
                onClick={() => setTab(TAB_COLLABS)}
                $active={tab === TAB_COLLABS}
              >
                Associated Collabs
              </TabButton>
              {collections.length > 0 && (
                <TabButton
                  onClick={() => setTab(TAB_COLLECTIONS)}
                  $active={tab === TAB_COLLECTIONS}
                >
                  Associated Collections
                </TabButton>
              )}
            </FlexRowGap>

            <Instructions>
              {tab === TAB_COLLABS ? (
                <>
                  This is a list of every post that has ever been paired with
                  the post above! AKA who has collaborated with this post!
                </>
              ) : (
                <>
                  This is a list of every collection that the post above has
                  been included in.
                </>
              )}
            </Instructions>
          </div>
          {tab === TAB_COLLABS ? (
            <CollabsList collabs={collabs} hiddenPiece={linkRoot} />
          ) : (
            <CollectionsList collections={collections} />
          )}
        </ContentContainer>
      </BottomSection>
      <EditPost
        showModal={showModal}
        setModalVisibility={setShowModal}
        onUpdate={savePost}
        post={post}
      />
    </PageContainer>
  );
};

export default PostLayout;
