import bardAPI from '@/bard-api';
import {
  PanelMask,
  SmallHorizontalDivider,
} from '@/components/library/basic-styled-components';
import PrimaryButton from '@/components/library/buttons/primary-button';
import TextButton from '@/components/library/buttons/text-button';
import { DropdownItem, DropdownMenu } from '@/components/library/dropdown';
import Dot from '@/components/library/notification-panel/dot';
import NotificationPanel from '@/components/library/notification-panel/notification-panel';
import { ItemHeader } from '@/components/library/typography/header';
import UserImgDefault from '@/components/user-img-default';
import { uRequestHandler } from '@/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { Bell, Bookmark } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import logoIcon from '../../media/icon-o.png';
import logo from '../../media/logo.png';
import HoverOptions from './hover-options';
import NavbarNavOption from './navbar-nav-option';
import NavbarOptionWrapper from './navbar-option-wrapper';
import NavbarRight from './navbar-right';
import NavbarWrapper, { NavbarWrapperWrapper } from './navbar-wrapper';

const Navbar = ({ hideOptions }) => {
  const [showNotifPanel, setShowNotifPanel] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showExplore, setShowExplore] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const isLoggedIn = currentUser.id;
  const navigate = useNavigate();
  const { loginWithRedirect, logout } = useAuth0();

  const getNotifications = async () => {
    try {
      const result = await bardAPI.get(
        `notifications?offset=0&limit=40&recent=True`,
      );
      setNotifications(result.data.results);
    } catch (error) {
      uRequestHandler(error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      getNotifications();
    }
  }, [isLoggedIn]);

  return (
    <NavbarWrapperWrapper>
      <NavbarWrapper>
        <NavbarOptionWrapper>
          <Link className="mobile-logo" to="/">
            <img src={logoIcon} alt="Bard logo" />
          </Link>
          <Link to="/">
            <img src={logo} alt="Bard logo" />
          </Link>
          <NavbarNavOption
            to="/"
            role="link"
            tabIndex={0}
            onMouseEnter={() => setShowExplore(true)}
            onMouseLeave={() => setShowExplore(true)}
          >
            <ItemHeader>Explore</ItemHeader>
          </NavbarNavOption>
        </NavbarOptionWrapper>
        {!hideOptions && (
          <>
            {isLoggedIn ? (
              <NavbarRight>
                <Link to={`/saved`}>
                  <Bookmark role="link" tabIndex={0} />
                </Link>
                <TextButton onClick={() => setShowNotifPanel(true)}>
                  <Bell />
                </TextButton>
                {notifications.length > 0 && (
                  <Dot $margin="-14px 0px 0px 36px" />
                )}
                {showNotifPanel && (
                  <NotificationPanel
                    clearNotifications={() => setNotifications([])}
                    notifications={notifications}
                    showNotifications={() => setShowNotifPanel(true)}
                    getNotifications={() => getNotifications()}
                  />
                )}
                <Dropdown
                  inline
                  icon={null}
                  trigger={<UserImgDefault user={currentUser} size="sm" />}
                  className="item"
                  onClick={() => setShowNotifPanel(false)}
                >
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => navigate(`/`)}
                      text="Collaborations"
                    />
                    <DropdownItem
                      onClick={() => navigate(`/collections`)}
                      text="Collections"
                    />
                    <DropdownItem
                      onClick={() => navigate(`/artists`)}
                      text="Artists"
                    />
                    <SmallHorizontalDivider />
                    <DropdownItem
                      onClick={() =>
                        navigate(`/profile/${currentUser.username}`)
                      }
                      text="Profile"
                    />
                    <DropdownItem
                      onClick={() => navigate(`/settings`)}
                      text="Settings"
                    />
                    <SmallHorizontalDivider />
                    <DropdownItem
                      onClick={() =>
                        logout({ returnTo: window.location.origin })
                      }
                      text="Sign Out"
                    />
                  </DropdownMenu>
                </Dropdown>
              </NavbarRight>
            ) : (
              <NavbarRight>
                <PrimaryButton
                  className="btn"
                  onClick={() => loginWithRedirect()}
                >
                  Log In
                </PrimaryButton>
              </NavbarRight>
            )}
          </>
        )}
      </NavbarWrapper>
      {showNotifPanel && <PanelMask onClick={() => setShowNotifPanel(false)} />}
      {showExplore && (
        <HoverOptions
          showExplorer={() => setShowExplore(true)}
          hideExplorer={() => setShowExplore(false)}
        />
      )}
    </NavbarWrapperWrapper>
  );
};

export default Navbar;
