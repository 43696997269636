import bardAPI from '@/bard-api';
import PrimaryButton from '@/components/library/buttons/primary-button';
import TextButton from '@/components/library/buttons/text-button';
import Modal from '@/components/library/modal-override';
import { uRequestHandler } from '@/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';

const ConfirmDelete = ({ showModal, setModalVisibility }) => {
  const state = useSelector((state) => state.currentUser);
  const [confirmed, setConfirmed] = useState(false);
  const { logout } = useAuth0();

  const deleteAccount = async () => {
    try {
      await bardAPI.delete(`users/${state.currentUser.id}/`);
      logout({ returnTo: window.location.origin });
    } catch (error) {
      uRequestHandler(error);
    }
  };

  return (
    <Modal size="tiny" open={showModal}>
      <Modal.Header>
        Are you sure you want to delete your Bard account?
        <X onClick={() => setModalVisibility(false)} className="modal-close" />
      </Modal.Header>
      <Modal.Content>
        <p className="basic-text">This action cannot be undone.</p>
        <br />
        <Checkbox
          label="Yes, I'm sure"
          checked={confirmed}
          onChange={() => setConfirmed(!confirmed)}
        />
      </Modal.Content>
      <Modal.Actions>
        <TextButton onClick={() => setModalVisibility(false)}>
          Cancel
        </TextButton>
        <PrimaryButton
          className="btn"
          onClick={deleteAccount}
          disabled={!confirmed}
        >
          Delete Account
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmDelete;
