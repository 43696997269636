import bardAPI from '@/bard-api';
import {
  FlexColumnCenter,
  FlexRowGap,
} from '@/components/library/basic-styled-components';
import PrimaryButton from '@/components/library/buttons/primary-button';
import TertiaryButton from '@/components/library/buttons/tertiary-button';
import AddToCollabWrapper from '@/components/library/collaborations/add-to-collab-wrapper';
import { FieldSubText, Text } from '@/components/library/typography/text';
import Tags from '@/components/tags';
import { resetFrontPage } from '@/store/landing';
import { uRequestHandler } from '@/utils';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Folder, Upload } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { Form } from 'semantic-ui-react';
import SecondaryButton from './library/buttons/secondary-button';
import PostingsList from './postings-list';

const UPLOAD_NEW = 0;
const UPLOAD_EXISTING = 1;

const STEP_1 = 0;
const STEP_2 = 1;

const AddLayout = ({
  forceUploadNew,
  type,
  collab,
  getCollabData,
  handleCloseModal,
  additionalData,
  inModal,
  additionalFields,
  resetPage,
  content,
  additionalFieldsFirst,
}) => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.currentUser);
  const [formErrors, setFormErrors] = useState({});
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(
    'Try dragging a file here or click to select a file to upload',
  );
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [filePreview, setFilePreview] = useState('');
  const [step, setStep] = useState(0);
  const [approach, setApproach] = useState(0);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [selected, setSelected] = useState('');
  const { id: collectionId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (forceUploadNew) {
      setStep(STEP_2);
      setApproach(UPLOAD_NEW);
    }
  }, [forceUploadNew]);

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFile(file);
    setFileName(file.name);

    const reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      type === 'audios'
        ? {
            'audio/*': [],
          }
        : {
            'image/*': [],
          },
    maxFiles: 1,
    onDrop: onDrop,
  });

  const setupApproach = async (approach) => {
    let data = [];
    if (approach === UPLOAD_EXISTING) {
      const response = await bardAPI.get(
        `${type}?user=${currentUser.id}&offset=0&limit=4`,
      );
      data = response.data.results;
      setTotalData(response.data.count);
    }

    setApproach(approach);
    setData(data);
    setStep(STEP_2);
  };

  const createCollabWithSelected = async () => {};

  const createCollabWithNewData = async () => {
    // Create generic collab data object
    const genericCollabData = {};

    if (file) {
      genericCollabData['file_data'] = file;
    }
    if (collab) {
      genericCollabData['collab'] = collab.id;
    }
    // Only add collection id if no collab selected
    if (collectionId && !collab) {
      genericCollabData['collection'] = collectionId;
    }

    // Combine generic data with post type data
    const collabData = { ...additionalData, ...genericCollabData };

    // Cast to FromData
    const formData = new FormData();
    const keys = Object.keys(collabData);
    keys.forEach((key) => {
      if (collabData[key] !== undefined) {
        formData.append(key, collabData[key]);
      }
    });

    tags.forEach((tag) => {
      formData.append('new_tags', tag);
    });

    return await bardAPI({
      method: 'post',
      url: `${type}/`,
      data: formData,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    try {
      let newCollab = null;
      // Selecting existing art
      if (selected) {
        newCollab = await bardAPI({
          method: 'post',
          url: `collabs/`,
          data: {
            collab: collab.id,
            post_type: type,
            selected: selected,
          },
        });
        // Creating new upload
      } else {
        newCollab = await createCollabWithNewData();
      }

      if (handleCloseModal) {
        handleCloseModal();
      }
      dispatch(resetFrontPage());

      navigate({
        pathname: `/${newCollab.data.id}`,
      });
      getCollabData();
    } catch (error) {
      setLoading(false);
      uRequestHandler(error);
    }
  };

  const getPage = async (offsetChange) => {
    setLoading(true);
    const newOffset = offset + offsetChange;

    setOffset(newOffset);
    const response = await bardAPI.get(
      `${type}/?user=${currentUser.id}&offset=${4 * newOffset}&limit=4`,
    );

    setTotalData(response.data.count);
    setData(response.data.results);
    setLoading(false);
  };

  const hideInput = content;
  return (
    <AddToCollabWrapper $modal={inModal}>
      {step === STEP_1 ? (
        <div className="swap-button-wrapper">
          <PrimaryButton
            className="btn"
            onClick={() => setupApproach(UPLOAD_NEW)}
          >
            <Upload />
            Upload a New Piece
          </PrimaryButton>
          <PrimaryButton
            className="btn"
            onClick={() => setupApproach(UPLOAD_EXISTING)}
          >
            <Folder />
            Choose one of your existing pieces
          </PrimaryButton>
        </div>
      ) : (
        <>
          {loading ? (
            <div className="add-to-collab-loading">
              <div>Uploading</div>
              <BeatLoader />
            </div>
          ) : (
            <>
              {approach === UPLOAD_NEW ? (
                <div>
                  <Form>
                    {additionalFieldsFirst && additionalFieldsFirst()}

                    {hideInput ? (
                      content
                    ) : (
                      <Form.Field>
                        <label>File*</label>

                        <div {...getRootProps({ className: 'drop-field' })}>
                          <input {...getInputProps()} />
                          {filePreview && type === 'visuals' ? (
                            <img src={filePreview} alt="icon" height="200" />
                          ) : (
                            <FlexColumnCenter $gap="8px">
                              <Text>{fileName}</Text>
                              <TertiaryButton className="btn">
                                {file ? 'Select New File' : 'Select File'}
                              </TertiaryButton>
                            </FlexColumnCenter>
                          )}
                        </div>
                        <FieldSubText>
                          Maximum file size is 10MB. Recommend file type is{' '}
                          {type === 'visuals' ? 'jpg' : 'mp3'}.
                        </FieldSubText>
                      </Form.Field>
                    )}

                    <Tags updateTags={setTags} tags={tags} />
                  </Form>
                  <div className="add-to-collab-footer">
                    <SecondaryButton onClick={() => resetPage()}>
                      Go back
                    </SecondaryButton>
                    <div className="footer-actions">
                      <PrimaryButton
                        className="btn"
                        onClick={(e) => handleSubmit(e)}
                      >
                        Save
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <PostingsList
                    endpoint={type}
                    data={data}
                    selectable
                    singleSelection={true}
                    setSelected={(item) => setSelected(item)}
                    selected={selected}
                  />
                  <div className="add-to-collab-footer">
                    <SecondaryButton onClick={() => resetPage()}>
                      Go back
                    </SecondaryButton>
                    <FlexRowGap $gap="4px">
                      {offset > 0 && (
                        <SecondaryButton
                          className="btn"
                          onClick={() => getPage(-1)}
                        >
                          Previous Page
                        </SecondaryButton>
                      )}
                      {offset + 1 * 4 < totalData && (
                        <SecondaryButton
                          className="btn"
                          onClick={() => getPage(1)}
                        >
                          Next Page
                        </SecondaryButton>
                      )}
                      <PrimaryButton
                        className="btn"
                        onClick={(e) => handleSubmit(e)}
                      >
                        Save
                      </PrimaryButton>
                    </FlexRowGap>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </AddToCollabWrapper>
  );
};

export default AddLayout;
