import styled from 'styled-components';

export const Instructions = styled.p`
  font-size: 22px;
  line-height: 28px;
  margin: ${({ $margin }) => ($margin ? $margin : '0px')};
`;

export const FieldLabel = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin: ${({ $margin }) => ($margin ? $margin : '0px')};
`;

export const Text = styled.p`
  font-size: 17px;
  margin: ${({ $margin }) => ($margin ? $margin : '0px')};
`;

export const SecondaryText = styled.p`
  font-size: 17px;
  color: ${({ theme }) => theme.colors.bodySubText};
  margin: ${({ $margin }) => ($margin ? $margin : '0px')};
`;

export const SemiImportant = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin: ${({ $margin }) => ($margin ? $margin : '0px')};
`;

export const ImportantText = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin: ${({ $margin }) => ($margin ? $margin : '0px')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Badge = styled.p`
  font-size: 15px;
  text-transform: uppercase;
`;

export const SubText = styled.p`
  font-size: 16px;
  margin: ${({ $margin }) => ($margin ? $margin : '0px')};
  color: ${({ theme, $color }) => ($color ? $color : theme.colors.subtext)};
`;

export const FieldSubText = styled.p`
  font-size: 15px;
  margin-top: 4px;
  text-align: right;
  color: ${({ theme }) => theme.colors.subtext};
  position: absolute;
  right: 0;
`;

export const FieldHighlight = styled.p`
  font-size: 17px;
  margin: ${({ $margin }) => ($margin ? $margin : '0px')};
  color: ${({ $success, theme }) =>
    $success ? theme.colors.success : theme.colors.error};
`;

export const FootNote = styled.p`
  font-size: 15px;
  color: ${({ theme, $color }) => ($color ? $color : theme.colors.subtext)};
`;
