import Banner from '@/components/banner';
import CollabsList from '@/components/collabs-list';
import IntroBanner from '@/components/intro-banner';
import PrimaryButton from '@/components/library/buttons/primary-button';
import SecondaryButton from '@/components/library/buttons/secondary-button';
import {
  ContentContainer,
  PageContainer,
} from '@/components/library/containers';
import { BannerHeader } from '@/components/library/typography/header';
import { Instructions } from '@/components/library/typography/text';
import Navbar from '@/components/navbar/index';
import CollabFilterBar from '@/containers/landing/filter-bar/index';
import { getCollaborationsPage } from '@/store/landing';
import { useCallback, useEffect, useState } from 'react';
import { ChevronUp } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

const Landing = () => {
  const [showGoToTop, setShowGoToTop] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const landingPage = useSelector((state) => state.landingPage);
  const dispatch = useDispatch();
  const {
    collaborations,
    total: totalCollabs,
    isLoading,
    maker,
    contentType,
    tags,
  } = landingPage;

  const trackScrolling = useCallback(() => {
    setShowGoToTop(window.scrollY > 200);

    const wrappedElement = document.getElementById('collabs-list-container');
    if (
      wrappedElement.getBoundingClientRect().bottom <=
        window.innerHeight + 700 &&
      !isLoading &&
      collaborations.length < totalCollabs
    ) {
      dispatch(getCollaborationsPage());
    }
  }, [isLoading, collaborations, totalCollabs, dispatch]);

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);
    return () => document.removeEventListener('scroll', trackScrolling);
  }, [trackScrolling]);

  useEffect(() => {
    dispatch(getCollaborationsPage());
  }, [maker, contentType, tags, dispatch]);

  const goToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const isLoggedIn = currentUser && currentUser.id;
  return (
    <PageContainer id="collabs-list-container">
      <Navbar />
      {!isLoggedIn ? (
        <Banner>
          <div className="shell-container">
            <div className="banner-wrapper">
              <div className="banner-text">
                <BannerHeader className="banner-title">
                  Storytelling for creatives
                </BannerHeader>
                <Instructions className="banner-secondary">
                  Bard is a place where you can find your inner storyteller.
                  Share stories, adventures, and poems through any medium that
                  you choose.
                </Instructions>
                <a
                  href="https://bard.social/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PrimaryButton className="btn">Learn More</PrimaryButton>
                </a>
              </div>
            </div>
          </div>
        </Banner>
      ) : (
        <IntroBanner
          header={'Collaborations'}
          subHeader={
            'Bard makes collaborations easy. Find artwork that inspires you and upload your work alongside theirs.'
          }
        />
      )}

      <ContentContainer>
        <CollabFilterBar />
        <CollabsList collabs={collaborations} loading={isLoading} />
      </ContentContainer>
      {showGoToTop && (
        <SecondaryButton
          className="btn"
          onClick={() => goToTop()}
          $round
          style={{
            position: 'fixed',
            bottom: '30px',
            right: '30px',
            zIndex: 30,
          }}
        >
          <ChevronUp />
        </SecondaryButton>
      )}
      <script>feather.replace()</script>
    </PageContainer>
  );
};

export default Landing;
