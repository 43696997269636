import bardAPI from '@/bard-api';
import { useCallback, useEffect, useState } from 'react';

import CollectionsList from '@/components/collections-list';
import IntroBanner from '@/components/intro-banner';
import {
  ContentContainer,
  PageContainer,
} from '@/components/library/containers';
import { Text } from '@/components/library/typography/text';
import Navbar from '@/components/navbar/index';
import { uRequestHandler } from '@/utils';

const Collections = () => {
  const [searching, setSearching] = useState(false);
  const [offset, setOffset] = useState(1);
  const [collections, setCollections] = useState([]);
  const [totalCollections, setTotalCollections] = useState(0);

  useEffect(() => {
    const getPage = async () => {
      setSearching(true);

      try {
        const response = await bardAPI.get(
          `collections/?offset=${offset * 10}&limit=10`,
        );

        setCollections((users) => users.concat(response.data.results));
        setTotalCollections(response.data.count);
        setSearching(false);
      } catch (error) {
        setSearching(false);
        uRequestHandler(error);
      }
    };

    getPage();
  }, [offset]);

  useEffect(() => {
    const getInitialData = async () => {
      setSearching(true);
      const response = await bardAPI.get(`collections/?offset=0&limit=10`);

      setCollections(response.data.results);
      setSearching(false);
    };

    getInitialData();
  }, []);

  const trackScrolling = useCallback(() => {
    const wrappedElement = document.getElementById('collections-container');
    if (
      wrappedElement.getBoundingClientRect().bottom <=
        window.innerHeight + 700 &&
      !searching &&
      collections.length < totalCollections
    ) {
      setOffset((offset) => offset + 1);
    }
  }, [searching, collections, totalCollections]);

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);
    return () => document.removeEventListener('scroll', trackScrolling);
  }, [trackScrolling]);

  return (
    <PageContainer id="collections-container">
      <Navbar />

      <IntroBanner
        header={'Collections'}
        subHeader={
          'Collections are worlds that have been collaboratively build with the community.'
        }
      />

      <ContentContainer>
        <CollectionsList collections={collections} />
        <Text>
          In order to encourage artists to collaborate on worlds, the number of
          available worlds have been limited to what is above. If you would like
          to start your own world then please reach out and we can...
        </Text>
      </ContentContainer>
    </PageContainer>
  );
};

export default Collections;
