import styled from 'styled-components';

const NavbarOptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  gap: 18px;

  img {
    height: 36px;
    margin-right: 24px;
  }

  .mobile-logo {
    display: none;
  }

  @media (max-width: 700px) {
    div,
    img,
    a {
      display: none;
    }

    .mobile-logo {
      display: block;

      img {
        display: block;
      }
    }
  }
`;

export default NavbarOptionWrapper;
