import { FlexRowSpace } from '@/components/library/basic-styled-components';
import PrimaryButton from '@/components/library/buttons/primary-button';
import { FormHeader } from '@/components/library/typography/header';
import { Text } from '@/components/library/typography/text';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Wrapper = styled.div`
  iframe {
    margin-bottom: 24px;
  }
`;

const SocialContainer = styled.div`
  margin: auto;
  display: flex;
  gap: 14px;
  align-items: center;
`;

const Contact = ({ nextStep, updateUser }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [subscribed, setSubscribed] = useState(false);

  const subscribe = async () => {
    mixpanel.track('Subscribe to newsletter', { user: currentUser.email });
    setSubscribed(true);
    await updateUser({ subscribed_newsletter: true });
  };

  return (
    <Wrapper>
      <FormHeader $margin="0 0 18px 0">You're done!</FormHeader>
      <Text $margin="0 0 18px 0">
        It's time to join the community. Here's a 2 minute overview of Bard and
        if you have any questions be sure to reach out through any one of our
        socials below!
      </Text>

      <iframe
        height="340"
        title="platform-intro"
        style={{ width: '100%' }}
        src="https://www.youtube.com/embed/SUeaZiM_ijs"
        allowFullScreen
      />

      <SocialContainer>
        <a
          href="https://www.youtube.com/channel/UCQTnMwtnL2HovKzywF7jqow"
          target="_blank"
          rel="noopener noreferrer"
        >
          Youtube
        </a>
        <a
          href="https://twitter.com/BardSocial"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>
      </SocialContainer>

      <FlexRowSpace $margin="24px 0 0 0">
        {subscribed ? (
          <p>Thank you for subscribing to the newsletter</p>
        ) : (
          <PrimaryButton className="btn" onClick={() => subscribe()}>
            Subscribe to email updates
          </PrimaryButton>
        )}
      </FlexRowSpace>

      <FlexRowSpace $margin="24px 0 0 0" $gap="18px">
        <div />
        <PrimaryButton className="btn" onClick={() => nextStep()}>
          Continue
        </PrimaryButton>
      </FlexRowSpace>
    </Wrapper>
  );
};

export default Contact;
