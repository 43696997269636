import bardAPI from '@/bard-api';
import PrimaryButton from '@/components/library/buttons/primary-button';
import SecondaryButton from '@/components/library/buttons/secondary-button';
import { fetchUserAfterUpdate } from '@/store/user';
import { uRequestHandler } from '@/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';

const SubScribeEmail = ({ user }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);

  const subscribe = async () => {
    try {
      await bardAPI.post(`email-subscription/`);
      dispatch(fetchUserAfterUpdate());
    } catch (e) {
      uRequestHandler(e);
    }
  };

  const unSubscribe = async () => {
    try {
      await bardAPI.delete(`email-subscription/`);
      dispatch(fetchUserAfterUpdate());
    } catch (e) {
      uRequestHandler(e);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '18px',
      }}
    >
      <div className="settings-section-description">
        We have one email list for release updates, event announcements, art and
        artist features, and everything else right now. We do our best to be
        conscious about how annoying email spam is so you can expect regular but
        not frequent emails from us.
      </div>
      <div>
        <Form>
          {currentUser.subscribed_newsletter ? (
            <SecondaryButton className="btn" onClick={unSubscribe}>
              Unsubscribe
            </SecondaryButton>
          ) : (
            <PrimaryButton className="btn" onClick={subscribe}>
              Subscribe
            </PrimaryButton>
          )}
        </Form>
      </div>
    </div>
  );
};

export default SubScribeEmail;
