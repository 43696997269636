import { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import styled from 'styled-components';
import logo from '../media/spart-to-bard.png';
import logowebp from '../media/spart-to-bard.webp';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  > div {
    position: relative;
    top: 20vh;
    max-width: 700px;
    margin: 0 auto 0 auto;
    padding: 0 18px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }
  picture {
    max-width: 120px;
    margin: auto;
    img {
      width: 100%;
    }
  }
  p,
  h1 {
    text-align: center;
  }
  span {
    font-size: 36px;
  }
`;

const SocialContainer = styled.div`
  margin: auto;
  display: flex;
  gap: 18px;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
`;

const PreScreen = () => {
  const [counter, setCounter] = useState(120);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <Wrapper>
      <div>
        <picture>
          <source srcSet={logowebp} type="image/webp" />
          <img src={logo} alt="spart and bard logo" />
        </picture>
        <h1>Bard is evolving</h1>
        <p>
          Previously known as Spart, Bard is evolving into something new. Bard
          is a community based social media platform for collaborative world
          building, storytelling, and discussion between artists, writers,
          actors, musicians and more! Be prepared to bring your stories to Bard
          when the time is up.
        </p>
        <Countdown date={new Date('July 19 2022 11:00:00 PST')} />
        <SocialContainer>
          <a
            href="https://twitter.com/BardSocial"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
        </SocialContainer>
      </div>
    </Wrapper>
  );
};

export default PreScreen;
