import styled from 'styled-components';

export const BannerHeader = styled.h1`
  font-size: 36px;
  font-weight: 600;
  margin: ${({ $margin }) => ($margin ? $margin : '0px')};
`;

export const PageHeader = styled.h1`
  font-size: 28px;
  font-weight: 600;
  margin: ${({ $margin }) => ($margin ? $margin : '0px')};
`;

export const SectionHeader = styled.h2`
  font-size: 26px;
  font-weight: 600;
  margin: ${({ $margin }) => ($margin ? $margin : '0px')};
  color: ${({ theme }) => theme.colors.bodyText};
`;

export const ItemHeader = styled.h3`
  font-size: 20px;
  font-weight: 500;
  margin: ${({ $margin }) => ($margin ? $margin : '0px')};

  @media (max-width: 800px) {
    display: ${({ $desktopOnly }) => ($desktopOnly ? 'none' : 'block')};
  }
`;

export const FormHeader = styled.h3`
  font-size: 22px;
  font-weight: 600;
  margin: ${({ $margin }) => ($margin ? $margin : '0px')};
`;
