import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import AddLayout from './add-layout';

const AddWriting = ({
  collab,
  inModal,
  handleCloseModal,
  getCollabData,
  forceUploadNew,
  resetPage,
}) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [writing, setWriting] = useState('');

  const content = () => (
    <Form.Field>
      <label>Writing</label>
      <ReactQuill
        name="writing"
        theme="snow"
        modules={{
          toolbar: [['bold', 'italic', 'underline', 'strike']],
        }}
        formats={['bold', 'italic', 'underline', 'strike']}
        placeholder="A poem, a backstory, or a tale of adventure?"
        value={writing}
        onChange={setWriting}
      />
    </Form.Field>
  );

  const additionalData = {
    writing,
  };
  return (
    <AddLayout
      inModal={inModal}
      type="writings"
      additionalData={additionalData}
      content={content()}
      collab={collab}
      handleCloseModal={handleCloseModal}
      getCollabData={getCollabData}
      resetPage={resetPage}
      forceUploadNew={!currentUser.writing_user || forceUploadNew}
    />
  );
};

export default AddWriting;
