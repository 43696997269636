import styled from 'styled-components';

export const Writing = styled.p`
  position: relative;
  max-height: 160px;
  overflow-y: hidden;
  overflow-x: hidden;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background:  ${({ theme }) =>
      `linear-gradient(rgba(255, 255, 255, 0) 100px, ${theme.colors.card.background}`});
  }
`;

const WritingPiece = ({ writing }) => {
  const { writing: content } = writing;

  return (
    <div className="writing-container">
      <Writing
        className="basic-text writing"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </div>
  );
};

export default WritingPiece;
