import bardAPI from '@/bard-api';
import ActiveCollection from '@/components/active-collection';
import Comments from '@/components/comments/index';
import CopyLink from '@/components/copy-link';
import {
  FlexColumn,
  FlexRowGap,
  FlexRowSpace,
  SmallHorizontalDivider,
} from '@/components/library/basic-styled-components';
import SecondaryButton from '@/components/library/buttons/secondary-button';
import TertiaryButton from '@/components/library/buttons/tertiary-button';
import CollabActionBar from '@/components/library/collab-action-bar';
import {
  BottomSection,
  ContentContainer,
  PageContainer,
} from '@/components/library/containers';
import IconToggle from '@/components/library/icon-toggle';
import LoadingEmptyState from '@/components/library/loading-empty-state';
import { Instructions } from '@/components/library/typography/text';
import NotSignedIn from '@/components/modals/not-signed-in';
import SwabCollab from '@/components/modals/swap-collab';
import Tags from '@/components/tags';
import AudioBlock from '@/containers/collaboration/audio-block';
import CollabHeader from '@/containers/collaboration/collab-header';
import VisualBlock from '@/containers/collaboration/visual-block';
import WritingBlock from '@/containers/collaboration/writing-block';
import { likeCollab, saveCollab } from '@/store/landing';
import { uRequestHandler } from '@/utils';
import { createContext, useCallback, useEffect, useState } from 'react';
import { Bookmark, ChevronUp, Heart, Twitter, Zap } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { TwitterShareButton } from 'react-share';
import Wrapper from './wrapper';

export const CollabContext = createContext(null);

const Collab = () => {
  const [collab, setCollab] = useState({});
  const [pageReady, setPageReady] = useState(false);
  const [showSwapModal, setShowSwapModal] = useState(false);
  const [editing, setEditing] = useState(null);
  const [showGoToTop, setShowGoToTop] = useState(false);
  const [showNotSignedIn, setShowNotSignedIn] = useState(false);
  const [liked, setLiked] = useState(false);
  const [saved, setSaved] = useState(false);
  const dispatch = useDispatch();
  const { id: collabId } = useParams();
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.currentUser.id);
  const landingPage = useSelector((state) => state.landingPage);
  const { reverseSaves, reverseLikes } = landingPage;
  const currentUser = useSelector((state) => state.currentUser);

  useEffect(() => {
    if (location.query && location.query.scrollToComments) {
      setTimeout(() => {
        scrollToComments();
      }, 300);
    }

    document.addEventListener('scroll', setShowGoToTop(window.scrollY > 200));
    return () =>
      document.removeEventListener(
        'scroll',
        setShowGoToTop(window.scrollY > 200),
      );
  }, []);

  const getCollabData = useCallback(async () => {
    setEditing(null);

    try {
      const collabData = await bardAPI.get(`collabs/${collabId}/`);
      setCollab(collabData.data);
      setPageReady(true);
    } catch (error) {
      uRequestHandler(error);
    }
  }, [collabId]);

  useEffect(() => {
    getCollabData();
  }, [getCollabData]);

  const toggleSwap = () => {
    if (isLoggedIn) {
      setShowSwapModal(true);
    } else {
      setShowNotSignedIn(true);
    }
  };

  useEffect(() => {
    if (!collab.collab_likes) return;

    const opposite = reverseLikes.includes(collab.id);
    for (let i = 0; i < collab.collab_likes.length; i++) {
      const like = collab.collab_likes[i];
      if (like.user.id === currentUser.id) {
        setLiked(!opposite);
        return;
      }
    }

    setLiked(opposite);
  }, [reverseLikes, currentUser, collab]);

  useEffect(() => {
    if (!collab.collab_saves) return;

    const opposite = reverseSaves.includes(collab.id);
    for (let i = 0; i < collab.collab_saves.length; i++) {
      const save = collab.collab_saves[i];
      if (save.user.id === currentUser.id) {
        setSaved(!opposite);
        return;
      }
    }

    setSaved(opposite);
  }, [reverseSaves, currentUser, collab]);

  const goToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const showEdit = (state) => {
    if (isLoggedIn) {
      setEditing(state);
    } else {
      setShowNotSignedIn(true);
    }
  };

  const scrollToComments = () => {
    scroller.scrollTo('commentsDivider', {
      duration: 1000,
      delay: 100,
      smooth: true,
    });
  };

  const renderCollab = () => {
    return (
      <CollabContext.Provider value={collab}>
        <Wrapper>
          {pageReady ? (
            <div>
              <CollabHeader
                collab={collab}
                scrollToComments={() => scrollToComments()}
                liked={liked}
                saved={saved}
              />
              {collab.collection && (
                <ActiveCollection collection={collab.collection} existingPost />
              )}
              <FlexColumn $gap={'36px'}>
                <VisualBlock
                  collab={collab}
                  getCollabData={() => getCollabData(collab.id)}
                  editing={editing === 0}
                  showEdit={(val) => showEdit(val)}
                />
                <AudioBlock
                  collab={collab}
                  getCollabData={() => getCollabData(collab.id)}
                  editing={editing === 1}
                  showEdit={(val) => showEdit(val)}
                />
                <WritingBlock
                  collab={collab}
                  getCollabData={() => getCollabData(collab.id)}
                  editing={editing === 2}
                  showEdit={(val) => showEdit(val)}
                />
              </FlexColumn>
            </div>
          ) : (
            <LoadingEmptyState isLoading={true} />
          )}
        </Wrapper>
      </CollabContext.Provider>
    );
  };

  return (
    <PageContainer $modal={true}>
      <ContentContainer $type="modal">{renderCollab()}</ContentContainer>
      <BottomSection>
        <ContentContainer $type="modal">
          <div className="collab-about">
            <FlexRowSpace>
              {isLoggedIn ? (
                <CollabActionBar $gap="16px">
                  <IconToggle
                    toggled={liked}
                    toggle={() => dispatch(likeCollab(collab.id))}
                    icon={<Heart />}
                    fillClass="fill-heart"
                  />
                  <IconToggle
                    toggled={saved}
                    toggle={() => dispatch(saveCollab(collab.id))}
                    icon={<Bookmark />}
                    fillClass="fill-bookmark"
                  />
                </CollabActionBar>
              ) : (
                <div />
              )}
              <FlexRowGap $gap="12px">
                <TwitterShareButton
                  children={<Twitter />}
                  url={`https://app.bard.social/${collab.id}`}
                  title={'Checkout this artwork on @BardSocial'}
                />
                <CopyLink
                  link={`${import.meta.env.VITE_APP_WEB}${collab.id}`}
                />
              </FlexRowGap>
            </FlexRowSpace>
            <Tags tags={collab.tags} />
            <TertiaryButton className="btn" onClick={toggleSwap}>
              <Zap />
              Collaborate on this post
            </TertiaryButton>
            <Instructions>
              Is there no room on this post to collaborate? Not to worry, click
              "Collaborate on this post" to continue collaborating and add your
              own piece to this set
            </Instructions>
          </div>
          <SmallHorizontalDivider name="commentsDivider" />
          <Comments collab={collab} />
        </ContentContainer>
      </BottomSection>
      <NotSignedIn
        showModal={showNotSignedIn}
        setModalVisibility={(visible) => setShowNotSignedIn(visible)}
      />
      <SwabCollab
        collab={collab}
        showModal={showSwapModal}
        setShowModal={setShowSwapModal}
      />
      {showGoToTop && (
        <SecondaryButton
          className="btn"
          onClick={() => goToTop()}
          $round
          style={{
            position: 'fixed',
            bottom: '30px',
            right: '30px',
            zIndex: 30,
          }}
        >
          <ChevronUp />
        </SecondaryButton>
      )}
    </PageContainer>
  );
};

export default Collab;
