import PrimaryButton from '@/components/library/buttons/primary-button';
import SecondaryButton from '@/components/library/buttons/secondary-button';
import Modal from '@/components/library/modal-override';
import { ItemHeader } from '@/components/library/typography/header';
import Tags from '@/components/tags';
import { useEffect, useState } from 'react';
import { X } from 'react-feather';
import ReactModal from 'react-modal';
import { Form, TextArea } from 'semantic-ui-react';

const EditPost = ({ showModal, setModalVisibility, post, onUpdate }) => {
  const [title, setTitle] = useState('');
  const [about, setAbout] = useState('');
  const [tags, setTags] = useState([]);

  useEffect(() => {
    ReactModal.setAppElement('body');
  }, []);

  useEffect(() => {
    if (post.id) {
      setTitle(post.name);
      setAbout(post.about);
      setTags(post.tags.map((appliedTag) => appliedTag.tag.text));
    }
  }, [post]);

  const updatePost = () => {
    const updatedData = {
      name: title,
      about: about,
      new_tags: tags,
    };

    onUpdate(updatedData);
  };

  return (
    <Modal size="tiny" open={showModal}>
      <Modal.Header>
        <ItemHeader>Edit Post Details</ItemHeader>
        <X onClick={() => setModalVisibility(false)} className="modal-close" />
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Title</label>
            <input
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Field>

          <Form.Field>
            <label>Description</label>
            <TextArea
              placeholder="Other information"
              name="about"
              value={about}
              onChange={(e) => setAbout(e.target.value)}
            />
          </Form.Field>

          <Tags updateTags={setTags} tags={tags} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <SecondaryButton
          className="btn"
          onClick={() => setModalVisibility(false)}
        >
          Close
        </SecondaryButton>
        <PrimaryButton className="btn" onClick={() => updatePost()}>
          Update
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  );
};

export default EditPost;
