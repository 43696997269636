import { FlexRowGap } from '@/components/library/basic-styled-components';
import PrimaryButton from '@/components/library/buttons/primary-button';
import Modal from '@/components/library/modal-override';
import { FormHeader } from '@/components/library/typography/header';
import { useState } from 'react';
import { ArrowLeft, Edit2, Headphones, Image, X } from 'react-feather';
import AddAudio from '../add-audio';
import AddVisual from '../add-visual';
import AddWriting from '../add-writing';

const MODAL_STEP_1 = 1;
const MODAL_STEP_2 = 2;

const UPLOADING_VISUAL = 0;
const UPLOADING_AUDIO = 1;
const UPLOADING_WRITING = 2;

const SwabCollab = ({ collab, showModal, setShowModal }) => {
  const [step, setStep] = useState(MODAL_STEP_1);
  const [contentType, setContentType] = useState(UPLOADING_VISUAL);

  const chooseStep1 = (content) => {
    setStep(MODAL_STEP_2);
    setContentType(content);
  };

  return (
    <Modal size="medium" open={showModal}>
      <Modal.Header>
        <FlexRowGap $gap="12px">
          {step === MODAL_STEP_2 && (
            <ArrowLeft
              onClick={() => setStep(MODAL_STEP_1)}
              className="modal-back"
            />
          )}
          {step === MODAL_STEP_1 ? (
            <FormHeader>Choose what you want to upload</FormHeader>
          ) : (
            <FormHeader>
              Upload a new piece or choose an existing one
            </FormHeader>
          )}
        </FlexRowGap>
        <X onClick={() => setShowModal(false)} className="modal-close" />
      </Modal.Header>
      <Modal.Content>
        {step === MODAL_STEP_1 && (
          <div className="swap-button-wrapper">
            <PrimaryButton
              className="btn"
              onClick={() => chooseStep1(UPLOADING_VISUAL)}
            >
              <Image />
              Add a Visual
            </PrimaryButton>
            <PrimaryButton
              className="btn"
              onClick={() => chooseStep1(UPLOADING_AUDIO)}
            >
              <Headphones />
              Add a Piece of Music or Voice Line
            </PrimaryButton>
            <PrimaryButton
              className="btn"
              onClick={() => chooseStep1(UPLOADING_WRITING)}
            >
              <Edit2 />
              Add a Story or Poem
            </PrimaryButton>
          </div>
        )}
        {step === MODAL_STEP_2 && (
          <div>
            {contentType === UPLOADING_VISUAL && (
              <AddVisual
                inModal={true}
                collab={collab}
                resetPage={() => setStep(MODAL_STEP_1)}
                handleCloseModal={() => setShowModal(false)}
              />
            )}
            {contentType === UPLOADING_AUDIO && (
              <AddAudio
                inModal={true}
                collab={collab}
                resetPage={() => setStep(MODAL_STEP_1)}
                handleCloseModal={() => setShowModal(false)}
              />
            )}
            {contentType === UPLOADING_WRITING && (
              <AddWriting
                inModal={true}
                collab={collab}
                resetPage={() => setStep(MODAL_STEP_1)}
                handleCloseModal={() => setShowModal(false)}
              />
            )}
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default SwabCollab;
