import bardAPI from '@/bard-api';
import { ItemHeader } from '@/components/library/typography/header';
import { SubText } from '@/components/library/typography/text';
import UserImg from '@/components/user-img';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const AUDIO_COLLAB = 1;
const VISUAL_COLLAB = 2;
const WRITING_COLLAB = 3;
const VOICE_COLLAB = 4;
const LIKE_NOTIF = 5;
const SAVE_NOTIF = 6;
const FOLLOW_NOTIF = 7;
const COLLAB_COMMENT = 9;
const COLLAB_REPLY = 11;

const NotificationWrapper = styled.div`
  position: relative;
  background-color: ${({ $panel, theme }) =>
    $panel ? '' : theme.colors.card.background};
  padding: ${({ $panel }) => ($panel ? '0 0 12px 0' : '8px 14px')};
  border-radius: 4px;
  margin: ${({ $panel }) => ($panel ? 'auto' : 'auto auto 6px auto')};
  display: flex;
  align-items: top;
  gap: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const ContentWrapper = styled.button`
  text-align: left;

  .notification-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
`;

const Notification = ({ notification, getNotifications, panel }) => {
  const navigate = useNavigate();

  const getMessage = (notification) => {
    const notif_type = notification.notification_type;
    if (notif_type === LIKE_NOTIF) {
      return `liked your post.`;
    }
    if (notif_type === SAVE_NOTIF) {
      return `saved your post.`;
    }
    if (notif_type === FOLLOW_NOTIF) {
      return `followed you.`;
    }
    if (notif_type === VISUAL_COLLAB) {
      return `posted a visual to match your work.`;
    }
    if (notif_type === AUDIO_COLLAB) {
      return `posted a piece of music to match your work.`;
    }
    if (notif_type === VOICE_COLLAB) {
      return `posted a voice line to match your work.`;
    }
    if (notif_type === WRITING_COLLAB) {
      return `posted a piece of writing to match your work.`;
    }
    if (notif_type === COLLAB_COMMENT) {
      return `tagged you in a comment ${notification.related_obj.comment}`;
    }
    if (notif_type === COLLAB_REPLY) {
      return `replied to your comment ${notification.related_obj.comment}`;
    }

    return '';
  };

  const getPathName = (notification) => {
    const notifType = notification.notification_type;
    if (
      [
        LIKE_NOTIF,
        SAVE_NOTIF,
        VISUAL_COLLAB,
        AUDIO_COLLAB,
        WRITING_COLLAB,
        VOICE_COLLAB,
      ].includes(notifType)
    ) {
      return `/${notification.related_obj.id}`;
    }
    if ([COLLAB_COMMENT, COLLAB_REPLY].includes(notifType)) {
      return `/${notification.related_obj.collab}`;
    }
    if ([FOLLOW_NOTIF].includes(notifType)) {
      return `/profile/${notification.sender.username}`;
    }
  };

  const getQueryParamaters = (notification) => {
    const notifType = notification.notification_type;
    if ([COLLAB_COMMENT, COLLAB_REPLY].includes(notifType)) {
      return {
        commentId: notification.related_obj.id,
      };
    }

    return {};
  };

  const handleClick = async (notification) => {
    await bardAPI.patch(`notifications/${notification.id}/`, {
      viewed: true,
    });
    if (getNotifications) {
      getNotifications();
    }
    navigate({
      pathname: getPathName(notification),
      query: getQueryParamaters(notification),
    });
  };

  return (
    <NotificationWrapper key={notification.id} $panel={panel}>
      <UserImg user={notification.sender} size="sm" />
      <ContentWrapper onClick={() => handleClick(notification)}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <ItemHeader>{notification.sender.username}</ItemHeader>
          <span className="middot">&middot;</span>
          <SubText>
            {moment.utc(notification.created_date).local().fromNow()}
          </SubText>
        </div>
        <div className="notification-text">{getMessage(notification)}</div>
      </ContentWrapper>
    </NotificationWrapper>
  );
};

export default Notification;
