import bardAPI from '@/bard-api';
import {
  ContentContainer,
  PageContainer,
} from '@/components/library/containers';
import LoadingEmptyState from '@/components/library/loading-empty-state';
import Notification from '@/components/library/notification-panel/notification';
import PageModalHeader from '@/components/library/page-modal-header';
import NotificationPageWrapper from '@/containers/notifications/wrapper';
import { uRequestHandler } from '@/utils';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const AUDIO_COLLAB = 1;
const VISUAL_COLLAB = 2;
const WRITING_COLLAB = 3;
const VOICE_COLLAB = 4;
const LIKE_NOTIF = 5;
const SAVE_NOTIF = 6;
const FOLLOW_NOTIF = 7;
const COLLAB_COMMENT = 9;
const COLLAB_REPLY = 11;

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [filter, setFilter] = useState({
    state: [],
    name: 'All',
  });
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);

  useEffect(() => {
    getNotifications();
  }, [currentUser]);

  const getNotifications = async () => {
    setIsLoading(true);
    try {
      const response = await bardAPI.get(`notifications?offset=0&limit=40`);

      setNotifications(response.data.results);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      uRequestHandler(error);
    }
  };

  const renderNotificationList = () => {
    const filteredNotifications = notifications.filter(
      (notification) =>
        filter.state.includes(notification.notification_type) ||
        filter.state.length === 0,
    );

    return filteredNotifications.map((notification) => {
      return (
        <Notification
          notification={notification}
          panel={false}
          key={notification.id}
        />
      );
    });
  };

  const tabs = [
    {
      state: [],
      name: 'All',
    },
    {
      state: [COLLAB_COMMENT, COLLAB_REPLY],
      name: 'Comments',
    },
    {
      state: [LIKE_NOTIF],
      name: 'Likes',
    },
    {
      state: [SAVE_NOTIF],
      name: 'Saves',
    },
    {
      state: [FOLLOW_NOTIF],
      name: 'Follows',
    },
    {
      state: [VISUAL_COLLAB, AUDIO_COLLAB, WRITING_COLLAB, VOICE_COLLAB],
      name: 'Collaborations',
    },
  ];

  return (
    <PageContainer $modal={true}>
      <ContentContainer $type="modal">
        <PageModalHeader location={'/'} title="Recent Notifications" />
        {currentUser ? (
          <NotificationPageWrapper>
            <div className="notification-page-header">
              <div className="notification-page-options">
                {tabs.map((tab) => (
                  <div
                    key={tab.name}
                    className={`notification-page-option${
                      filter.name === tab.name ? ' selected' : ''
                    }`}
                    role="link"
                    tabIndex={0}
                    onClick={() => setFilter(tab)}
                    onKeyPress={() => setFilter(tab)}
                  >
                    {tab.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="notification-page-body">
              {renderNotificationList()}
              <LoadingEmptyState
                noData="You have no notifications of this type"
                isLoading={isLoading}
                data={notifications.filter(
                  (notification) =>
                    filter.state.includes(notification.notification_type) ||
                    filter.state.length === 0,
                )}
              />
            </div>
          </NotificationPageWrapper>
        ) : (
          <div />
        )}
      </ContentContainer>
    </PageContainer>
  );
};

export default Notifications;
