import bardAPI from '@/bard-api';
import { FlexRowSpace } from '@/components/library/basic-styled-components';
import PrimaryButton from '@/components/library/buttons/primary-button';
import { FormHeader } from '@/components/library/typography/header';
import { Instructions, Text } from '@/components/library/typography/text';
import { uRequestHandler } from '@/utils';
import { loadStripe } from '@stripe/stripe-js';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const PerkCard = styled.div`
  flex: 1;
  border: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
  padding: 18px;
  border-radius: 8px;
`;

const OptionsContainer = styled.div`
  display: flex;
  gap: 18px;
  margin-top: 24px;
  align-items: center;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_KEY);

const PickAPlan = ({ nextStep }) => {
  const [failedPayment, setFailedPayment] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);

  const fetchCheckoutSession = async () => {
    try {
      const result = await bardAPI.get(`checkout-session/?onboarding=true`);
      return result.data;
    } catch (error) {
      uRequestHandler(error);
      return {};
    }
  };

  const subscribe = async () => {
    setFailedPayment(false);
    mixpanel.track('Initiate pro plan', { user: currentUser.email });
    // Call your backend to create the Checkout session.
    const session = await fetchCheckoutSession();
    // When the customer clicks on the button, redirect them to Checkout.
    if (session && session.id) {
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (error) {
        mixpanel.track('Payment failed', { user: currentUser.email });
        setFailedPayment(true);
      } else {
        mixpanel.track('Payment success', { user: currentUser.email });
        nextStep();
      }
    } else {
    }
  };

  const BENEFITS = [
    'Commission advertising',
    'Profile flair',
    'No watermarks',
    'Discord privileges',
    'Sneak peaks into ongoing work',
    'More to come',
  ];

  return (
    <>
      <FormHeader $margin="0 0 18px 0">Become a Patron!</FormHeader>
      <Text $margin="0 0 18px 0">
        Bard is entirely funded by the patrons who support it.
      </Text>
      <OptionsContainer>
        <div style={{ flex: 1 }}>
          <Instructions $margin="0 0 24px 0">
            Your support is truly appreciated.
          </Instructions>
          <PrimaryButton className="btn" onClick={() => subscribe()}>
            Become a Patron
          </PrimaryButton>
        </div>
        <PerkCard>
          {BENEFITS.map((b) => (
            <Text>{b}</Text>
          ))}
        </PerkCard>
      </OptionsContainer>

      <FlexRowSpace $margin="24px 0 0 0" $gap="18px">
        {failedPayment && (
          <p>
            Payment failed, that's on us. You can try again or skip this for
            now.
          </p>
        )}
        <PrimaryButton className="btn" onClick={() => nextStep()}>
          Maybe later
        </PrimaryButton>
      </FlexRowSpace>
    </>
  );
};

export default PickAPlan;
