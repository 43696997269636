import styled from 'styled-components';

const TertiaryButton = styled.button`
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.buttons.tertiary.color};
  background-color: ${({ theme }) => theme.colors.buttons.tertiary.background};
  border: 1px solid ${({ theme }) => theme.colors.buttons.tertiary.background};
  padding: 0.4rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .drop-field-button p {
    color: ${({ theme }) => theme.colors.buttons.tertiary.color};
    font-weight: 600;
  }

  & svg {
    color: ${({ theme }) => theme.colors.buttons.tertiary.icon};
    margin-right: 0.7rem;
    height: 16px;
    width: 16px;
    flex: 1 0 16px;
  }
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.buttons.tertiary.background};
  }

  &.btn:focus,
  &.btn:active {
    border: solid 1px ${({ theme }) => theme.colors.buttons.tertiary.background};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.buttons.tertiary.disabled};
    border: solid 1px ${({ theme }) => theme.colors.buttons.tertiary.disabled};
    color: #dcdcdc;
  }
`;

export default TertiaryButton;
