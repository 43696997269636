import PrimaryButton from '@/components/library/buttons/primary-button';
import EmptyBlock from '@/components/library/empty-block';

const AddPieceSlot = ({ showEdit, icon, text }) => (
  <EmptyBlock>
    <PrimaryButton className="btn" onClick={showEdit} onKeyPress={showEdit}>
      {icon}
      {text}
    </PrimaryButton>
  </EmptyBlock>
);

export default AddPieceSlot;
