import bardAPI from '@/bard-api';
import { FlexRowSpace } from '@/components/library/basic-styled-components';
import PrimaryButton from '@/components/library/buttons/primary-button';
import { FormHeader } from '@/components/library/typography/header';
import { FieldHighlight, Text } from '@/components/library/typography/text';
import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Form } from 'semantic-ui-react';

const UsernameAndTerms = ({ nextStep, updateUser }) => {
  const [username, setUsername] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const currentUser = useSelector((state) => state.currentUser);
  const autoConnectPossible = currentUser.auto_connect_possible;

  useEffect(() => {
    setUsername(currentUser.username);
    setTermsChecked(currentUser.agreed_to_terms);
    mixpanel.track('Start sign up', { user: currentUser.email });
  }, [currentUser]);

  const checkUsername = async () => {
    const validUsername = new RegExp('^[a-z0-9]+$', 'i');
    if (!validUsername.test(username)) {
      return 'Usernames can only have letters and numbers';
    }
    const result = await bardAPI.get(
      `users/?username=${username}&ignore_self=true`,
    );
    if (result.data.length > 0) {
      return 'Username is taken';
    }

    return '';
  };

  const validate = async () => {
    const newErrors = {};

    const usernameError = await checkUsername();
    if (usernameError) {
      newErrors.username = usernameError;
    }

    setErrors(newErrors);
    return !Object.keys(newErrors).some((error) => error.length !== 0);
  };

  const handleSubmit = async () => {
    const isValid = await validate();
    if (!isValid) return;

    await updateUser({
      username,
      agreed_to_terms: termsChecked,
    });
    mixpanel.track('Submit username', { user: currentUser.email });
    nextStep();
  };

  return (
    <>
      <FormHeader $margin="0 0 18px 0">Create Your Character</FormHeader>
      <Text $margin="0 0 18px 0">Who is the storyteller within you?</Text>
      <Form>
        <Form.Field>
          <label>Username</label>
          <input
            placeholder="Username"
            value={username}
            name="username"
            onChange={(e) => setUsername(e.target.value)}
          />
          {errors.username && (
            <FieldHighlight>{errors.username}</FieldHighlight>
          )}
        </Form.Field>
        <Form.Field>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '4px',
              gap: '4px',
            }}
          >
            <Checkbox
              label=""
              checked={termsChecked}
              onChange={() => setTermsChecked(!termsChecked)}
            />
            <Text $margin="0">
              I agree to the
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.termsfeed.com/live/a4effae6-987a-4143-b04e-8861ac051e56"
              >
                &nbsp;Terms and Conditions&nbsp;
              </a>
              and
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.termsfeed.com/live/5a2c488d-4fd6-477a-8110-85aa653b9f49"
              >
                &nbsp;Privacy Policy&nbsp;
              </a>
            </Text>
          </div>
        </Form.Field>

        <FlexRowSpace $margin="24px 0 0 0">
          <div />
          <PrimaryButton
            className="btn"
            onClick={() => handleSubmit()}
            disabled={!termsChecked}
          >
            Continue
          </PrimaryButton>
        </FlexRowSpace>
      </Form>
    </>
  );
};

export default UsernameAndTerms;
